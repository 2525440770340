/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
  authMode: ['local'],
  authLocalOptions: {
    mode: 'unique_password',
    defaultLogin: 'digital+emeis@mediameeting.fr',
  },
  nuxt: {
    head: {
      title: 'emeis RADIO, la radio qui parle de vous',
      meta: [
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
        },
        {
          hid: 'description',
          name: 'description',
          content: `Nous vous donnons rendez-vous chaque mardi à 13h45 pour un nouveau podcast !`,
        },
        {
          'http-equiv': 'X-UA-Compatible',
          content: 'IE=edge',
        },
        {
          'http-equiv': 'X-UA-Compatible',
          content: 'IE=edge',
        },
        {
          charset: 'utf-8',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex,nofollow',
        },
        {
          hid: 'ogSiteName',
          name: 'og:site_name',
          content: 'emeis RADIO, la radio qui parle de vous',
        },
        {
          hid: 'ogTitle',
          name: 'og:title',
          content: 'emeis',
        },
        {
          hid: 'ogDescription',
          name: 'og:description',
          content: `Nous vous donnons rendez-vous chaque mardi à 13h45 pour un nouveau podcast !`,
        },
        {
          hid: 'ogType',
          name: 'og:type',
          content: 'website',
        },
      ],
    },
  },
  hasAutoplayContent: true,
  hasSuggestedContent: true,
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '67c85c9caa78d5082b07e212',
          i18n: {
            fr: {
              title: 'La consult',
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
            },
          },
          heading: {
            color: '#8AD2EC',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
      ],
    },
  ],
}
